.loading {
  padding: 0.3rem 0.5rem;
  border-color: var(--borderDefault);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  display: flex;
  color: var(--textDefault);
  background: var(--backgroundInput);
}
