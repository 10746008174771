.drop_container {
  background-color:  var(--dateChat-background);
  border: 2px solid  var(--green-polichat) !important;
}

.drop_item {
  border-radius: 0.25rem;
  margin: 0 0.25rem;
  color: var(--primary);
}

.drop_item:hover,
.drop_item__active {
  background-color: var(--primary-25);
}

.menu_container {
  max-height: 200px;
  overflow-y: scroll;
}

.search_container {
  width: 100%;
}
.search_input > input {
  background-color: transparent !important ;
  border-color: var(--primary) !important;
}

@media only screen and (max-width: 600px) {
  .drop_item {
    margin: 0;
  }

  .menu_container {
    max-width: 200px;
    overflow-x: hidden;
  }

  .menu_container > button {
    max-width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: smaller;
    padding: 0;
  }
}
