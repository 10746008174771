.credits_loading {
  display: inline-flex;
  align-items: center;
}

.credits_selected_container {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.credits_selected {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: flex-start;

  font-size: 0.6rem;
  color: var(--secondary-font-color);
}

.credits_selected_title {
  width: 10rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: ltr;
  text-align: left;
}

.credits_selected_values {
  margin-bottom: 0;
  font-size: 0.8rem;
}

.credits_selected_values span:nth-child(2) {
  font-size: 0.8rem;
  font-weight: 400;
  color: var(--primary);
}

.credits_value {
  font-size: 1.08rem;
  font-weight: bold;
  color: var(--attention);
}

.credits_value_warning {
  color: var(--warning);
}

.credits_value_danger {
  color: var(--danger);
}

.credits_menu_title {
  font-size: 1.2rem;
  width: -webkit-fill-available;
  font-weight: bold;
}

.credits_menu_date {
  flex-direction: column;
  width: -webkit-fill-available;
  margin: 0;
}

.credits_menu_date span:nth-child(1) {
  font-weight: bold;
  align-self: flex-end;
}
.credits_menu_date span:nth-child(2) {
  font-size: 0.7rem;
  text-align: right;
}

.credits_channel_info {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: flex-start;
  align-content: center;
  gap: 0.2rem;
}
.credits_channel_info :hover {
  cursor: pointer;
}

.credits_channel_info > label {
  font-weight: 500;
  width: 10rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: ltr;
  text-align: left;
}

.credits_footer {
  padding: 0 1rem;
}

.credits_footer > button {
  justify-content: center;
}

.credits_waba_container {
  display: flex;
  flex-direction: column;
}

.credits_waba_channels {
  padding-left: 1.5rem;
}

.credits_waba_channels > li {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 0.8rem;
}
.credits_waba_channels > li > span {
  font-size: 0.8rem;
  font-weight: 500;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 10rem;
}

.plateBoxWl {
  left: 180px !important;
}
