/* === ESTILOS GLOBAIS PARA DISPOSITIVOS MÓVEIS ===*/

@media only screen and (max-width: 600px) {
  /* === BEGIN NAVIGATION BAR === */
  .mobileTopActions {
    display: inherit;
  }

  .sidebarContainer {
    width: 0;
    float: right;
  }

  .sidebarContainer .operatorMenu,
  .sidebarContainer .operatorMenu,
  .sidebarContainer .supportMenu,
  .sidebarContainer.hasMobileMenu {
    display: none;
  }

  .sidebarContainer.hasMobileMenu.showMobileMenu {
    display: block;
  }

  .bodyContentContainer .chatContainer {
    align-items: center;
  }

  .showMobileMenu {
    display: inline;
    width: 20vw;
    transition: width 0.3s ease;
    margin-right: 0.15rem;
    float: right;
    border-left: 1px solid var(--border-color);
    border-right: none;
  }

  .showMobileMenu .contactListItem i {
    display: none;
  }

  .showVersion {
    display: none;
  }

  /* === END NAVIGATION BAR === */

  /* === BEGIN TOASTS === */
  .Toastify__toast-container {
    width: 100% !important;
    height: 1rem !important;
    padding: 0 3.8vw !important;
  }

  .Toastify__toast {
    padding: 0 !important;
  }

  /* === END TOASTS === */

  /*BACK BUTTON / BOTÃO DE VOLTAR*/
  #backToMenuMobile {
    margin-left: -2vw;
    margin-right: 3vw;
    padding: 0;
  }

  /* hidden */
  .hidden,
  .hiddenForce {
    display: none !important;
  }

  /* === LOADER PARA CARREGAR MENSAGENS === */
  .chatLoadingLoader img {
    max-width: 100% !important;
  }
  /*MODALS*/

  /* .modal-dialog {
    max-width: 100vw !important;
  } */

  .modal-open .modal.fade.show .modal-content {
    padding: 0;
    min-width: 0;
  }

  .modal-open .modal.fade.show .modal-dialog {
    width: 80% !important;
    margin: 9vw auto;
  }

  .modal-header .close,
  .modal-header .closeButton {
    display: none;
  }

  .modal-header {
    padding-bottom: 0 !important;
  }
  .modal-title {
    justify-content: space-around !important;
  }

  .modal-title i {
    font-size: 6vw !important;
    padding: 3.8vw !important;
  }

  .modal-title span {
    font-size: 3.8vw !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 3.8vw;
  }

  .modal-body {
    padding: 2vw !important;
    margin: 0 3vw !important;
    font-size: 3.8vw !important;
  }

  /* MODAL DE DELETAR CONTATO */
  .info-channel-to-delete {
    font-size: 3.8vw !important;
  }

  .modal-footer {
    flex-wrap: nowrap !important;
    align-items: auto !important;
  }

  .modal-footer button {
    padding: 2vw;
    font-size: 4vw;
  }

  /*END MODAL STYLES*/
}
