/*.detailsContainer{
  height: 100%;
}*/

.scrollable-content {
    height: 70%;
    overflow-y: auto;
  }

.mergeContactButtonContainer{
  text-align: center;
  padding-top: 1em;
}
  .letterAvatarContainer div:before {
    content: "";
    float: left;
    padding-top: 100%;
}
  .boxContainerCrm{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 1.8em;
    cursor: default;
  }
  .moreInfosOpportunities .title, .moreInfosTickets .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-left: 10px;
}
.moreInfosOpportunities .title h5, .moreInfosTickets .title h5 {
font-size: 16px;

}
.moreInfosOpportunities .card, .moreInfosTickets .card {
  border: 0;
  color: var(--secondary-font-color);
  margin-top: 1em;
  background: transparent;
}

  .opportunity-item {
    background-color: var(--box-info-color);
    font-size: 0.88em;
    padding: 7%;
    border-radius: 8px;
    cursor: pointer;
    margin: 10px;
  }
  .opportunity-item:hover {
    background-color: var(--actived-chat-background);
  }
  .opportunity-item label {
    display: block;
    margin-bottom: 0em;
  }

  .opportunity-list {
    margin-left: -15px;
  }

  .opportunity-title {
    font-weight: bold;
    color:var(--primary);
    width: 100%;
  }

  .unique-value {

  }

  .responsible {
    font-style: italic;
  }

  .funnel {
  }

  .created-at {
  }



  .seeMore{
    cursor: pointer;
    color: #6666de;
    font-size: 14px;
    margin: 5px;
    float: right;
  }

  .createdAt{
    font-size:0.75em;
  }

  .align-start {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    text-align: left;
    word-break: break-all;
    width: 100%;
  }

  .infos-view{
    display: inline-block;
    align-items: end;
    justify-content: right;
    float: right;
    width: 70%;
    text-align: left;
    padding-left: 5px;
    font-size: small;
  }

  .vertical-line {
    width: 100%;
    height: 1px;
    background-color: #E9EAED;
    border: 1px;
  }

  .align-tags {
    display:flex;
    text-align: left;
    margin-right: 5px;
    border-radius: 50px;
  }

 svg{
  height: 15px;
 }

 .btn-new-crm{
  height: 100%;
  width: 100%;
 }

 .info-user-crm{
  border-radius: 4px;
  background: rgba(0, 158, 255, 0.50);
 }

 .row {
  border-radius: initial !important;
  box-shadow: none !important;
}

.opportunitiesAndTicketsEmbeded {
  display: flex;
  flex-direction: column;
  gap: 1em;
  height: 100%;
}

.opportunitiesAndTicketsEmbeded .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.opportunitiesAndTicketsEmbeded .opportunities {
  background-color: var(--primary-background-color);
  padding: 5px;
}