
  .textBoxContainer .schedule-review {
    display: grid;
    /* flex-direction: row;  */
    position: absolute; 
    left: 50%; 
    top: 50%;
    transform: translate(-50%, -50%); 
    justify-content: space-between;
    
    width: 100%; 
    
    max-height: 200%; 
    min-height: 199%;
    
    
    margin: 0 auto; 
    padding: 20px; 
    
    background-color: var(--primary-background-color); 
    border: 1px solid #ddd; 
    border-radius: 8px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    
    z-index: 9999; 
    transition: all 0.3s ease-in-out;
  }
  .schedule-review p {
    margin: 10px 0;
  }
  .schedule-review .buttons .reviewbuttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .schedule-review .message-container .buttons .nextbutton{
    display: flex;
    justify-content: left; 
  }
  .schedule-review .message-container {
    flex-grow: 1; 
    overflow: auto; 
    padding: 10px;
    background-color: var(--light-background); 
    /* border: 1px solid #ccc;  */
  }
  
  /* Ajustes para telas menores */
  @media (max-width: 768px) {
    .schedule-review {
      width: 90%; /* Ajusta a largura em telas menores */
      padding: 10px; /* Reduz o espaçamento interno */
    }
  }
  
  /* Estilização do botão "x" */
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    color:var(--secondary-font-color)
  }
  
  .close-button:hover {
    color: #ee1919; /* Cor do "x" quando o mouse está sobre ele */
  }
  
  /* Estilização dos botões de ação */
  .buttons {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  

  .buttons button:hover {
    background-color: #ddd;
  }
  .schedule-step {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    background-color: #33336f;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .schedule-step h2 {
    margin-bottom: 1rem;
  }
  
  .schedule-step label {
    margin-bottom: 0.5rem;
  }
  
  .schedule-step input {
    width: 100%;
    margin-bottom: 1rem;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .schedule-step .button-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  
  .groupTextArea {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .main-text {
    color: var(--primary);
  }

  .icon-text-container {
    display: flex;   
    justify-content: flex-start; 
  }
  
  .aligned-icon {
    margin-right: 8px; 
  }
  
  .aligned-text {
    text-align: left; 
  }
  .message-container {
    align-items: left;
    max-height: 100px; /* Define a altura máxima do contêiner */
    overflow-y: auto; 
    word-wrap: break-word; 
    word-break: break-word; 
    max-width: 100%;
  }
  
  .groupActions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
  }
  
  .groupActionsLeft,
  .groupActionsRight {
    display: flex;
    align-items: center;
  }
  
  .groupActionsRight .button {
    margin-left: 0.5rem;
  }

  