.container__audio-control {
  background: var(--primary-background-color);
  padding: 0.75rem;
  margin: 0.85rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  border-radius: 5px;
  justify-content: space-between;
}

.container__first-element,
.container__second-element,
.container__third-element {
  display: flex;
  align-items: center;
}

.container__first-element {
  background-color: var(--secondary-background-color);
  padding: 10px;
  border-radius: 100%;
}

/* === INPUT RANGE - BARRA DE PROGRESSO DO AUDIO === */

input[type='range'] {
  box-sizing: border-box;
  -webkit-appearance: none;
  max-width: 100%;
  height: 0.4rem;
  border-radius: 100px;
  outline: none !important;
  appearance: none;
  background-color: var(--secondary-font-color);
}

/* Estilo específico para Google Chrome */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  box-sizing: border-box;
  background: var(--green-polichat);
  cursor: pointer;
  border-radius: 30px;
  outline: none;
  width: 1rem;
  height: 1rem;
}

/* Estilo específico para Mozilla */
input[type='range']::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background: var(--green-polichat);
  cursor: pointer;
  border-radius: 50%;
  border: none;
  outline: none;
}

/* Estilo da Barra total */
input[type='range']::-moz-range-track {
  background-color: var(--secondary-font-color);
  border-radius: 30px;
  border: none;
  height: 100%;
}

/* Estilo da Barra de progresso que já foi consumida */
input[type='range']::-moz-range-progress {
  background-color: var(--green-polichat);
  height: 100%;
  border-radius: 30px;
  border: none;
  max-width: 100%;
}

/* Estilo específico para IE */
input[type='range']::-ms-fill-lower {
  background-color: #fff;
  height: 100%;
  border-radius: 30px;
  border: none;
}
input[type='range']::-ms-fill-upper {
  background-color: #fff;
  border-radius: 30px;
  border: none;
  height: 100%;
}

/* === INPUT RANGE END === */
